import { gql } from "@apollo/client";
import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from "@ionic/react";
import orderBy from "lodash/orderBy";
import React, { useMemo,useState } from "react";

import { OutfitCard, OutfitCardData, OutfitCardFragment } from "./OutfitCard";

export const OutfitList: React.FunctionComponent<{
  items: OutfitListItem[];
  selectedItems?: OutfitListItem[];
  showOwner?: boolean;
  onItemClick?: (outfit: OutfitListItem) => void;
}> = ({
  items,
  selectedItems = [],
  showOwner = false,
  onItemClick = () => {},
}) => {
  const [count, setCount] = useState(4);
  const onInfinite = (e: any) => {
    setCount((count) => count + 2);
    e.target.complete();
  };

  const orderedItems = useMemo(
    () => orderBy(items, "createdAt", "desc"),
    [items]
  );

  return (
    <IonGrid className="outfit-list">
      {orderedItems.slice(0, count).map((outfit) => (
        <IonRow key={outfit.id}>
          <IonCol>
            <OutfitCard
              outfit={outfit}
              selected={selectedItems.includes(outfit)}
              showOwner={showOwner}
              onClick={() => onItemClick(outfit)}
            />
          </IonCol>
        </IonRow>
      ))}

      <IonInfiniteScroll
        onIonInfinite={onInfinite}
        threshold="100px"
        disabled={count >= items.length}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </IonGrid>
  );
};

export const OutfitListItemFragment = gql`
  fragment OutfitListItem on Outfit {
    id
    ...OutfitCard
  }
  ${OutfitCardFragment}
`;

export interface OutfitListItem extends OutfitCardData {
  id: string;
}
