import { IonButton } from "@ionic/react";
import { useRouteMatch } from "react-router";
import styled from "styled-components";

const items = [
  { path: "/main/wardrobe/capsules", title: "capsules" },
  // { path: "/main/wardrobe.outfits", title: "outfits" },
  { path: "/main/wardrobe/clothing", title: "items" },
];

export const WardrobeNavigation = () => {
  const match = useRouteMatch();

  return (
    <StyledWrapper>
      {items.map((item, i) => (
        <IonButton
          key={i}
          routerLink={item.path}
          routerDirection="root"
          size="small"
          fill={item.path === match.url ? "solid" : "outline"}
          style={{
            width: 70,
            "--background":
              item.path === match.url ? "var(--ion-color-primary)" : "white",
          }}
        >
          {item.title}
        </IonButton>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0px;
  right: 0px;
  text-align: center;
  white-space: nowrap;
`;
