import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { CapsuleFormComplete } from "./CapsuleFormComplete";

export const CapsuleCreateCompletePage = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe/capsules/create" />
          </IonButtons>
          <IonTitle>Create capsule</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <CapsuleFormComplete />
      </IonContent>
    </IonPage>
  );
};
