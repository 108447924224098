import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext, LOGGED_OUT } from "../auth";

export function useAuth() {
  const { accessToken, setToken } = useContext(AuthContext);
  const history = useHistory();

  return useMemo(() => {
    async function login(accessToken: string) {
      await setToken(accessToken);
      history.push("/main");
    }

    async function logout() {
      await setToken(LOGGED_OUT);
      history.push("/login", { direction: "back" });
    }

    return {
      isReady: accessToken !== undefined,
      isAuthed: !!accessToken && accessToken !== LOGGED_OUT,
      accessToken,
      login,
      logout,
    };
  }, [accessToken, setToken, history]);
}

export type Auth = ReturnType<typeof useAuth>;
