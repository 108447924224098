import { gql, useMutation } from "@apollo/client";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";
import { globeOutline, lockClosedOutline } from "ionicons/icons";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { ClothingListItem } from "../../components/ClothingList";
import { FloatingButton } from "../../components/FloatingButton";
import {
  OutfitList,
  OutfitListItem,
  OutfitListItemFragment,
} from "../../components/OutfitList";
import { Capsule, CapsulePageFragment } from "./CapsulePage";

const TAGS = [
  "formal",
  "business",
  "office",
  "punk",
  "grunge",
  "bohemian",
  "casual",
  "athletic",
  "retro",
  "vacation",
  "preppy",
  "streetwear",
];

type LocationState = {
  combinations?: ClothingListItem[][];
};

export const CapsuleFormComplete = ({ capsule }: { capsule?: Capsule }) => {
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [outfits, setOutfits] = useState<OutfitListItem[]>([]);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [isPublic, setPublic] = useState(false);
  const [showTagSelection, setShowTagSelection] = useState(false);
  const [selectedOutfits, setSelectedOutfits] = useState<OutfitListItem[]>([]);

  // Prefill from capsule (edit mode)
  useEffect(() => {
    if (capsule) {
      setTitle(capsule.title);
      setTags(capsule.tags);
      setPublic(capsule.isPublic);

      const existingCombinations: Array<string[]> = [];
      for (const outfit of capsule.outfits) {
        const itemIds = outfit.items.map((x) => x.id).sort();
        existingCombinations.push(itemIds);
      }
      setSelectedOutfits(
        outfits.filter((outfit) =>
          existingCombinations.some((combo) =>
            isEqual(combo, outfit.items.map((x) => x.id).sort())
          )
        )
      );
    }
  }, [capsule, outfits]);

  const [createOutfitMutation] = useMutation<{ createOutfit: OutfitListItem }>(
    CREATE_OUTFIT_MUTATION
  );
  const [createCapsuleMutation] = useMutation(CREATE_CAPSULE_MUTATION);
  const [editCapsuleMutation] = useMutation(EDIT_CAPSULE_MUTATION);

  useEffect(() => {
    if (!history.location.state) return;
    const { combinations = [] } = history.location.state as LocationState;

    const previews = combinations.map((items, i) => ({
      id: i.toString(),
      owner: { id: "0", handle: "n/a" },
      items,
    }));

    setOutfits(previews);
  }, [history.location.state]);

  const toggleOutfitSelection = (outfit: OutfitListItem) => {
    if (selectedOutfits.includes(outfit)) {
      setSelectedOutfits(selectedOutfits.filter((x) => x !== outfit));
    } else {
      setSelectedOutfits([...selectedOutfits, outfit]);
    }
  };

  const toggleTag = (tag: string) => {
    const newTags = tags.includes(tag)
      ? tags.filter((x) => x !== tag)
      : [...tags, tag];
    if (newTags.length > 3) return;
    setTags(newTags);
  };

  async function handleSubmit() {
    if (processing) return;
    setProcessing(true);

    // create outfits
    const outfitIds: string[] = [];
    for (const outfit of selectedOutfits) {
      const itemIds = outfit.items.map((x) => x.id).sort();
      // check if an outfit with the item combination already exists
      if (capsule) {
        let matchingOutfitId = undefined;
        for (const existingOutfit of capsule.outfits) {
          const existingItemIds = existingOutfit.items.map((x) => x.id).sort();
          if (isEqual(itemIds, existingItemIds)) {
            matchingOutfitId = existingOutfit.id;
            break;
          }
        }
        if (matchingOutfitId) {
          outfitIds.push(matchingOutfitId);
          continue;
        }
      }
      // otherwise create a new outfit
      const res = await createOutfitMutation({ variables: { itemIds } });
      if (res.data) {
        outfitIds.push(res.data.createOutfit.id);
      }
    }

    // edit / create capsule
    if (capsule) {
      await editCapsuleMutation({
        variables: {
          capsuleId: capsule.id,
          title,
          tags,
          isPublic,
          outfitIds,
        },
      });
    } else {
      await createCapsuleMutation({
        variables: {
          title,
          tags,
          isPublic,
          outfitIds,
        },
        refetchQueries: ["CapsuleListPageQuery"],
      });
    }

    // reset state
    setProcessing(false);
    // setTitle("");
    // setTags([]);
    // setPublic(false);
    // setSelectedOutfits([]);

    // navigate to capsules list
    if (capsule) {
      history.push(`/main/wardrobe/capsules/${capsule.id}/view`);
    } else {
      history.push("/main/wardrobe/capsules");
    }
  }

  return (
    <>
      <IonItem>
        <IonInput
          type="text"
          placeholder="Name your capsule"
          value={title}
          onIonChange={(e) => setTitle(e.detail.value!)}
          maxlength={20}
          autofocus
        />
      </IonItem>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: 8,
          paddingRight: 8,
        }}
      >
        {showTagSelection ? (
          <IonChip onClick={() => setShowTagSelection(false)} outline>
            <IonIcon icon={closeOutline} />
            <IonLabel>Close</IonLabel>
          </IonChip>
        ) : (
          <div>
            {tags.map((tag) => (
              <IonChip
                onClick={() => setShowTagSelection(!showTagSelection)}
                key={tag}
                outline
              >
                {tag}
              </IonChip>
            ))}
            {tags.length === 0 && (
              <IonChip
                onClick={() => setShowTagSelection(!showTagSelection)}
                outline
              >
                <IonIcon icon={addOutline} />
                <IonLabel>Add tags</IonLabel>
              </IonChip>
            )}
          </div>
        )}

        <IonChip
          onClick={() => setPublic((b) => !b)}
          style={{ overflow: "visible" }}
          outline
        >
          {isPublic ? (
            <>
              <IonIcon icon={globeOutline} color="primary" />
              <IonLabel>public</IonLabel>
            </>
          ) : (
            <>
              <IonIcon icon={lockClosedOutline} />
              <IonLabel>private</IonLabel>
            </>
          )}
        </IonChip>
      </div>

      {showTagSelection && (
        <IonCard style={{ marginTop: "1em" }}>
          <IonCardHeader>
            <IonCardSubtitle>
              Select tags <small>(up to 3)</small>
            </IonCardSubtitle>
            <IonIcon
              onClick={() => setShowTagSelection(false)}
              icon={closeOutline}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "1.5em",
              }}
            />
          </IonCardHeader>
          <IonCardContent style={{ padding: 4 }}>
            {TAGS.map((tag) => (
              <IonChip
                key={tag}
                onClick={() => toggleTag(tag)}
                color={tags.includes(tag) ? "dark" : "medium"}
                disabled={tags.length >= 3 && !tags.includes(tag)}
                outline
              >
                {tag}
              </IonChip>
            ))}
          </IonCardContent>
        </IonCard>
      )}

      <OutfitList
        items={outfits}
        selectedItems={selectedOutfits}
        onItemClick={toggleOutfitSelection}
      />

      <FloatingButton
        onClick={handleSubmit}
        disabled={!title || selectedOutfits.length < 1 || processing}
      >
        {capsule ? "Edit capsule" : "Create capsule"}
      </FloatingButton>
    </>
  );
};

const CREATE_OUTFIT_MUTATION = gql`
  mutation CreateOutfitMutation($itemIds: [ID!]!) {
    createOutfit(itemIds: $itemIds) {
      ...OutfitListItem
    }
  }
  ${OutfitListItemFragment}
`;

const CREATE_CAPSULE_MUTATION = gql`
  mutation CreateCapsuleMutation(
    $title: String!
    $outfitIds: [ID!]!
    $tags: [String!]!
    $isPublic: Boolean!
  ) {
    createCapsule(
      title: $title
      outfitIds: $outfitIds
      tags: $tags
      isPublic: $isPublic
    ) {
      id
    }
  }
`;

const EDIT_CAPSULE_MUTATION = gql`
  mutation EditCapsuleMutation(
    $capsuleId: ID!
    $title: String!
    $outfitIds: [ID!]!
    $tags: [String!]!
    $isPublic: Boolean!
  ) {
    editCapsule(
      capsuleId: $capsuleId
      title: $title
      outfitIds: $outfitIds
      tags: $tags
      isPublic: $isPublic
    ) {
      ...CapsulePage
    }
  }
  ${CapsulePageFragment}
`;
