import { gql, useQuery } from "@apollo/client";
import { useStorageItem } from "@capacitor-community/storage-react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { uniqBy } from "lodash";
import { useParams } from "react-router";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";

export const CapsuleItemsPage: React.FC = () => {
  const { capsuleId } = useParams<{ capsuleId: string }>();
  const [packedHash, setPackedHash] = useStorageItem<string>(
    "packing_list." + capsuleId,
    ""
  );
  const packedIds = packedHash?.split(",");

  const query = useQuery<{ capsules: Capsule[] }>(GET_CAPSULE_ITEMS, {
    variables: { capsuleId },
  });

  const capsule = query.data?.capsules?.[0];
  if (!capsule) return null;

  const uniqueItems = uniqBy(capsule.outfits.map((o) => o.items).flat(), "id");

  function toggleItem(item: ClothingListItem) {
    if (packedIds?.includes(item.id)) {
      setPackedHash(packedIds.filter((x) => x !== item.id).join(","));
    } else {
      setPackedHash([...(packedIds ?? []), item.id].join(","));
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/main/wardrobe/capsules/${capsuleId}/view`}
            />
          </IonButtons>
          <IonTitle>Items in {capsule.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setPackedHash("")}>Clear</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ClothingList
          items={uniqueItems}
          selectedItems={uniqueItems.filter((x) => packedIds?.includes(x.id))}
          onItemClick={toggleItem}
        />
      </IonContent>
    </IonPage>
  );
};

export const GET_CAPSULE_ITEMS = gql`
  query CapsulePageQuery($capsuleId: ID!) {
    capsules(capsuleIds: [$capsuleId]) {
      id
      title
      outfits {
        id
        items {
          id
          ...ClothingListItem
        }
      }
    }
  }
  ${ClothingListItemFragment}
`;
export interface Capsule {
  id: string;
  title: string;
  outfits: {
    id: string;
    items: ClothingListItem[];
  }[];
}
