/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/* Theme variables */
import "./theme/variables.css";
import "./App.css";

import { ApolloProvider } from "@apollo/client";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import { createApolloClient } from "./apollo";
import { AuthProvider } from "./auth";
import { useAuth } from "./hooks/useAuth";
import { AppUrlListener } from "./listeners/AppUrlListener";
import { AuthPage } from "./pages/AuthPage";
import { LoginPage } from "./pages/LoginPage";
import { LoginPendingPage } from "./pages/LoginPendingPage";
import { MainNavigator } from "./pages/MainNavigator";

const App: React.FC = () => {
  useEffect(() => {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      StatusBar.setStyle({ style: Style.Light });
    }
  });

  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener />
        <AuthProvider>
          <Root />
        </AuthProvider>
      </IonReactRouter>
    </IonApp>
  );
};

const Root = () => {
  const auth = useAuth();

  // Loading the access token initially from storage takes some time, wait for it.
  if (!auth.isReady) return null;

  return (
    <ApolloProvider client={createApolloClient(auth)}>
      <IonRouterOutlet>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/login/pending" component={LoginPendingPage} />
        <Route exact path="/auth/:sessionId/:otpToken" component={AuthPage} />

        <Route path="/main">
          {auth.isAuthed ? <MainNavigator /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/">
          <Redirect to="/main" />
        </Route>
      </IonRouterOutlet>
    </ApolloProvider>
  );
};

export default App;
