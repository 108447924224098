import { gql, useQuery } from "@apollo/client";
import {
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { earthOutline, sparklesOutline } from "ionicons/icons";
import { useLayoutEffect } from "react";
import { Redirect, Route, useHistory, useRouteMatch } from "react-router-dom";

import { CapsuleCreateCompletePage } from "./capsule/CapsuleCreateCompletePage";
import { CapsuleCreatePage } from "./capsule/CapsuleCreatePage";
import { CapsuleEditCompletePage } from "./capsule/CapsuleEditCompletePage";
import { CapsuleEditPage } from "./capsule/CapsuleEditPage";
import { CapsuleItemsPage } from "./capsule/CapsuleItemsPage";
import { CapsuleListPage } from "./capsule/CapsuleListPage";
import { CapsulePage } from "./capsule/CapsulePage";
import { ClothingAddPage } from "./clothing/ClothingAddPage";
import { ClothingEditPage } from "./clothing/ClothingEditPage";
import { ClothingListPage } from "./clothing/ClothingListPage";
import { ExploreCapsuleItemsPage } from "./explore/ExploreCapsuleItemsPage";
import { ExploreCapsulePage } from "./explore/ExploreCapsulePage";
import { ExploreImportItemsPage } from "./explore/ExploreImportItemsPage";
import { ExploreImportPage } from "./explore/ExploreImportPage";
import { ExploreListPage } from "./explore/ExploreListPage";
import { OutfitCreatePage } from "./outfit/OutfitCreatePage";
import { OutfitEditPage } from "./outfit/OutfitEditPage";
import { OutfitListPage } from "./outfit/OutfitListPage";
import { ProfilePage } from "./ProfilePage";
import { UserSetupPage } from "./UserSetupPage";

export const MainNavigator = () => {
  const history = useHistory();
  const match = useRouteMatch();

  const query = useQuery(ROOT_QUERY);
  const me = query.data?.me;

  // Hack required to have tab buttons navigate to the tabs main page (originalHref) instead of the latest page.
  // Unfortunately can't use onClick() on <IonTabButton> due to https://github.com/ionic-team/ionic-framework/issues/22511
  useLayoutEffect(() => {
    const buttons = document.querySelectorAll("ion-tab-button");
    buttons.forEach((button) => {
      const tab = button.getAttribute("tab");
      button.onclick = function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        history.push(`/main/${tab}`, { direction: "none" });
      };
    });
  }, [me, history]);

  if (!me) return null;

  if (!me.handle) {
    return <UserSetupPage />;
  }

  const hasNoItems = query.data.clothingItems.length === 0;
  const wardrobeDefaultPath = hasNoItems
    ? `${match.url}/wardrobe/clothing`
    : `${match.url}/wardrobe/capsules`;

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path={`${match.path}/wardrobe`}>
            <Redirect to={wardrobeDefaultPath} />
          </Route>

          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules`}
            component={CapsuleListPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/create`}
            component={CapsuleCreatePage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/create/complete`}
            component={CapsuleCreateCompletePage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/:capsuleId/view`}
            component={CapsulePage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/:capsuleId/items`}
            component={CapsuleItemsPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/:capsuleId/edit`}
            component={CapsuleEditPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/capsules/:capsuleId/edit/complete`}
            component={CapsuleEditCompletePage}
          />

          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/outfits`}
            component={OutfitListPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/outfits/create`}
            component={OutfitCreatePage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/outfits/:outfitId/edit`}
            component={OutfitEditPage}
          />

          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/clothing`}
            component={ClothingListPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/clothing/add`}
            component={ClothingAddPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(wardrobe)/clothing/:itemId/edit`}
            component={ClothingEditPage}
          />

          <Route
            exact
            path={`${match.path}/:tab(explore)`}
            component={ExploreListPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(explore)/capsules/:capsuleId`}
            component={ExploreCapsulePage}
          />
          <Route
            exact
            path={`${match.path}/:tab(explore)/capsules/:capsuleId/items`}
            component={ExploreCapsuleItemsPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(explore)/capsules/:capsuleId/import`}
            component={ExploreImportPage}
          />
          <Route
            exact
            path={`${match.path}/:tab(explore)/capsules/:capsuleId/import/items`}
            component={ExploreImportItemsPage}
          />

          <Route exact path={`${match.path}/profile`} component={ProfilePage} />

          <Route exact path={match.path}>
            <Redirect to={wardrobeDefaultPath} />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="wardrobe" href={`${match.url}/wardrobe`}>
            <IonIcon icon={sparklesOutline} />
          </IonTabButton>
          <IonTabButton tab="explore" href={`${match.url}/explore`}>
            <IonIcon icon={earthOutline} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

const ROOT_QUERY = gql`
  query MainUserQuery {
    me {
      id
      handle
    }
    clothingItems(limit: 1) {
      id
    }
  }
`;
