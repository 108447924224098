import { gql, useQuery } from "@apollo/client";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { useHistory } from "react-router-dom";

import { IntroArrow, IntroContent, IntroHeader } from "../../components/Intro";
import {
  OutfitList,
  OutfitListItem,
  OutfitListItemFragment,
} from "../../components/OutfitList";
import { WardrobeNavigation } from "../../components/WardrobeNavigation";

export const OutfitListPage = () => {
  const history = useHistory();

  const query = useQuery<OutfitListPageQueryResult>(OUTFIT_LIST_PAGE_QUERY);
  const outfits = query.data?.outfits ?? [];
  const clothingItems = query.data?.clothingItems ?? [];

  return (
    <IonPage>
      <IonContent className="safe-area">
        <OutfitList
          items={outfits}
          onItemClick={(outfit) =>
            history.push(`/main/wardrobe.outfits/${outfit.id}/edit`)
          }
        />

        {!query.loading &&
          outfits.length === 0 &&
          (clothingItems.length ? (
            <>
              <IntroHeader>Create outfit</IntroHeader>
              <IntroContent>
                Next, create an outfit by pressing the plus button below.
              </IntroContent>
              <IntroArrow direction="right" />
            </>
          ) : (
            <>
              <IntroHeader large>Welcome to Dresser!</IntroHeader>
              <IntroContent>
                Looks like you haven't added any clothing items yet, head over
                to the clothing tab and get started!
              </IntroContent>
              <IntroArrow direction="bottom" />
            </>
          ))}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink="/main/wardrobe.outfits/create">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <WardrobeNavigation />
      </IonContent>
    </IonPage>
  );
};

const OUTFIT_LIST_PAGE_QUERY = gql`
  query OutfitListPageQuery {
    outfits {
      ...OutfitListItem
    }
    clothingItems(limit: 1) {
      id
    }
  }
  ${OutfitListItemFragment}
`;
interface OutfitListPageQueryResult {
  outfits: OutfitListItem[];
  clothingItems: { id: string }[];
}
