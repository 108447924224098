import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { Center } from "../components/Center";
import { useAuth } from "../hooks/useAuth";

export const ProfilePage = () => {
  const auth = useAuth();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main" />
          </IonButtons>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <Center>
          <IonItem onClick={auth.logout} button>
            Logout
          </IonItem>
        </Center>
      </IonContent>
    </IonPage>
  );
};
