import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { Auth } from "./hooks/useAuth";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

export function createApolloClient(auth: Auth) {
  const authLink = setContext(async (_, { headers }) => {
    const token = auth.accessToken;
    // console.log(_.operationName, { token });

    // TODO: automatically catch all invalid tokens for non-public mutations / queries

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : undefined,
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (
      networkError &&
      "statusCode" in networkError &&
      networkError.statusCode === 401
    ) {
      auth.logout();
    }
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
}
