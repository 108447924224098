import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import { CapsuleForm } from "./CapsuleForm";

export const CapsuleCreatePage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe/capsules" />
          </IonButtons>
          <IonTitle>Create capsule</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <CapsuleForm />
      </IonContent>
    </IonPage>
  );
};
