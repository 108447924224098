import { useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useParams } from "react-router";

import { CapsuleForm } from "./CapsuleForm";
import { Capsule, GET_CAPSULE } from "./CapsulePage";

export const CapsuleEditPage: React.FC = () => {
  const { capsuleId } = useParams<{ capsuleId: string }>();

  const query = useQuery<{ capsules: Capsule[] }>(GET_CAPSULE, {
    variables: { capsuleId },
  });

  const capsule = query.data?.capsules?.[0];
  if (!capsule) return null;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/main/wardrobe/capsules/${capsuleId}/view`}
            />
          </IonButtons>
          <IonTitle>Edit capsule</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <CapsuleForm capsule={capsule} />
      </IonContent>
    </IonPage>
  );
};
