import React from "react";
import styled from "styled-components";

import { hexToRgbaCss } from "../utils/color";

export const ColorSelection: React.FunctionComponent<{
  colors: Color[];
  selectedColor?: Color;
  onItemClick?: (color: Color) => void;
}> = ({ colors, selectedColor, onItemClick = () => {} }) => {
  return (
    <StyledWrapper>
      {colors.map((color, i) => (
        <span
          key={i}
          onClick={() => onItemClick(color)}
          style={
            selectedColor?.key === color.key || true
              ? { backgroundColor: color.hex }
              : { backgroundColor: hexToRgbaCss(color.hex, 0.5) }
          }
        />
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.span`
  span {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    border: 1px solid var(--ion-color-dark);
    margin: 4px;
    vertical-align: middle;
  }
`;

export interface Color {
  key: string;
  hex: string;
}
