import { IonSkeletonText } from "@ionic/react";
import React from "react";
import { useState } from "react";

export const ImageWithPlaceholder: React.FunctionComponent<
  React.ImgHTMLAttributes<any> & { height?: number }
> = ({ style = {}, onLoad = () => {}, alt = "", ...params }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && (
        <IonSkeletonText
          style={{ height: style.height ?? 200, margin: 0 }}
          animated
        />
      )}

      <img
        {...params}
        alt={alt}
        style={{ ...style, height: loaded ? style.height : 0 }}
        onLoad={(e) => {
          setLoaded(true);
          onLoad(e);
        }}
      />
    </>
  );
};
