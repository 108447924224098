import { gql } from "@apollo/client";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonIcon,
} from "@ionic/react";
import {
  globeOutline,
  lockClosedOutline,
  checkmarkCircleOutline,
  downloadOutline,
} from "ionicons/icons";

import { OutfitCard, OutfitCardData, OutfitCardFragment } from "./OutfitCard";

export const CapsuleCard: React.FC<{
  capsule: CapsuleCardData;
  showOwner?: boolean;
  showPublic?: boolean;
  showImported?: boolean;
  onClick?: () => void;
}> = ({
  capsule,
  showOwner = false,
  showPublic = true,
  showImported = false,
  onClick = () => {},
}) => {
  return (
    <IonCard onClick={onClick}>
      <IonCardHeader>
        <IonCardTitle>{capsule.title}</IonCardTitle>
        {showOwner && capsule.owner.handle}
      </IonCardHeader>

      <OutfitCard outfit={capsule.outfits[0]} />

      <IonCardContent
        style={{
          display: "flex",
          alignItems: "center",
          padding: 8,
        }}
      >
        {capsule.tags.map((tag) => (
          <IonChip key={tag} color="medium" outline style={{ marginBlock: 0 }}>
            {tag}
          </IonChip>
        ))}
        {showPublic && (
          <IonIcon
            {...(capsule.isPublic
              ? { icon: globeOutline, color: "primary" }
              : { icon: lockClosedOutline, color: "medium" })}
            style={{ fontSize: 20, marginLeft: "auto" }}
          />
        )}
        {showImported && (
          <IonIcon
            {...(capsule.hasImported
              ? { icon: checkmarkCircleOutline, color: "primary" }
              : { icon: downloadOutline, color: "medium" })}
            style={{ fontSize: 20, marginLeft: "auto" }}
          />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export const CapsuleCardFragment = gql`
  fragment CapsuleCard on Capsule {
    id
    title
    tags
    isPublic
    owner {
      id
      handle
    }
    outfits(limit: 1) {
      ...OutfitCard
    }
    hasImported
  }
  ${OutfitCardFragment}
`;

export interface CapsuleCardData {
  id: string;
  title: string;
  tags: string[];
  isPublic: boolean;
  owner: { id: string; handle: string };
  outfits: OutfitCardData[];
  hasImported: boolean;
}
