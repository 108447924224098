import styled from "styled-components";

export const Center = styled.div`
  width: 100%;
  text-align: center;

  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;
