import { gql, useMutation,useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Center } from "../../components/Center";
import {
  ClothingCard,
  ClothingCardData,
  ClothingCardFragment,
} from "../../components/ClothingCard";
import { ClothingList } from "../../components/ClothingList";
import { FloatingButton } from "../../components/FloatingButton";

type LocationState = {
  outfitIds: string[];
};

export function ExploreImportItemsPage() {
  const history = useHistory();
  const { capsuleId } = useParams<{ capsuleId: string }>();

  const page = useRef<HTMLElement>(null);
  const [processing, setProcessing] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [current, setCurrent] = useState<ImportCandidate>();
  const [selections, setSelections] = useState<Record<string, ClothingItem>>(
    {}
  );

  const { outfitIds = [] } = (history.location.state ?? {}) as LocationState;

  const [importCapsuleMutation] = useMutation(IMPORT_CAPSULE_MUTATION);

  const query = useQuery<ExploreImportItemsQueryResult>(
    EXPLORE_IMPORT_ITEMS_QUERY,
    { variables: { capsuleId, outfitIds }, skip: outfitIds.length === 0 }
  );
  const importCandidates = query.data?.importCandidates ?? [];

  const sourceIds = importCandidates.map((c) => c.source).flatMap((s) => s.id);
  const isComplete = sourceIds.every((i) =>
    Object.keys(selections).includes(i)
  );

  const handleSubmit = async () => {
    if (!isComplete) return;

    setProcessing(true);

    await importCapsuleMutation({
      variables: {
        capsuleId,
        outfitIds,
        itemSelections: Object.keys(selections).map((sourceId) => ({
          sourceId,
          selectedId: selections[sourceId].id,
        })),
      },
      refetchQueries: ["ExplorePageQuery", "CapsuleListPageQuery"],
    });

    setProcessing(false);
    setSelections({});

    history.push("/main/explore");
  };

  return (
    <IonPage ref={page}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>select matching items</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            {importCandidates.map((importCandidate) => {
              const sourceId = importCandidate.source.id;
              const selection = selections[sourceId];
              return (
                <IonCol key={sourceId} size="6">
                  <ClothingCard
                    item={selection ?? importCandidate.source}
                    onClick={() => {
                      setCurrent(importCandidate);
                      setModalOpen(true);
                    }}
                  >
                    {!selection && (
                      <Center style={{ fontSize: 36 }}>
                        <IonIcon icon={addCircleOutline} />
                      </Center>
                    )}
                  </ClothingCard>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>

        <IonModal
          isOpen={isModalOpen}
          presentingElement={page.current || undefined}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>choose item</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setModalOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {current && (
              <ClothingList
                items={current.candidates}
                onItemClick={(item) => {
                  setSelections((selections) => ({
                    ...selections,
                    [current.source.id]: item,
                  }));
                  setModalOpen(false);
                }}
              />
            )}
          </IonContent>
        </IonModal>

        <FloatingButton
          disabled={!isComplete || processing}
          onClick={handleSubmit}
        >
          {processing ? "Importing..." : "Import capsule"}
        </FloatingButton>
      </IonContent>
    </IonPage>
  );
}

const EXPLORE_IMPORT_ITEMS_QUERY = gql`
  query ExploreImportItemsQuery($capsuleId: ID!, $outfitIds: [ID!]!) {
    importCandidates(capsuleId: $capsuleId, outfitIds: $outfitIds) {
      source {
        id
        ...ClothingCard
      }
      candidates {
        id
        ...ClothingCard
      }
    }
  }
  ${ClothingCardFragment}
`;

type ClothingItem = { id: string } & ClothingCardData;

type ImportCandidate = {
  source: ClothingItem;
  candidates: ClothingItem[];
};

type ExploreImportItemsQueryResult = {
  importCandidates: ImportCandidate[];
};

const IMPORT_CAPSULE_MUTATION = gql`
  mutation ImportCapsuleMutation(
    $capsuleId: ID!
    $outfitIds: [ID!]!
    $itemSelections: [CapsuleImportItemSelection!]!
  ) {
    importCapsule(
      capsuleId: $capsuleId
      outfitIds: $outfitIds
      itemSelections: $itemSelections
    ) {
      id
    }
  }
`;
