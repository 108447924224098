import { App as CapApp } from "@capacitor/app";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const AppUrlListener: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener("appUrlOpen", (data: any) => {
      // https://dresser.catchlab.se/path/param1?foo=bar => /path/param1?foo=bar
      const url = data.url.split("://").pop();
      const slug = url.slice(url.indexOf("/"), url.length);
      if (slug) {
        history.push(slug);
      }
      // if there's no match, do nothing - regular routing
    });
  }, [history]);

  return null;
};
