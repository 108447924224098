import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { gql, useMutation, useQuery } from "@apollo/client";
import {
  IonActionSheet,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { airplaneOutline, ellipsisHorizontal } from "ionicons/icons";
import { globeOutline, lockClosedOutline } from "ionicons/icons";
import { chevronBackOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import {
  OutfitCard,
  OutfitCardData,
  OutfitCardFragment,
} from "../../components/OutfitCard";

export const CapsulePage: React.FC = () => {
  const history = useHistory();
  const [actionsVisible, setActionsVisible] = useState(false);

  const { capsuleId } = useParams<{ capsuleId: string }>();

  const [deleteMutation] = useMutation(DELETE_CAPSULE, {
    variables: { capsuleId },
    refetchQueries: ["CapsuleListPageQuery", "OutfitListPageQuery"],
  });

  const query = useQuery<{ capsules: Capsule[] }>(GET_CAPSULE, {
    variables: { capsuleId },
  });

  const capsule = query.data?.capsules?.[0];
  if (!capsule) return null;

  const handleDelete = async () => {
    await deleteMutation();
    history.push("/main/wardrobe/capsules");
  };

  const handleEdit = () => {
    history.push(`/main/wardrobe/capsules/${capsuleId}/edit`);
  };

  const uniqueItemIds = Array.from(
    new Set(capsule.outfits.map((o) => o.items.map((x) => x.id)).flat())
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink="/main/wardrobe/capsules">
              <IonIcon icon={chevronBackOutline} />
              Back
            </IonButton>
          </IonButtons>
          <IonTitle>{capsule.title}</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={ellipsisHorizontal}
              color="primary"
              slot="icon-only"
              style={{ marginRight: 8 }}
              onClick={() => setActionsVisible(true)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <IonRow>
            <IonCol
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <div>
                {capsule.tags.map((tag) => (
                  <IonChip key={tag} outline>
                    {tag}
                  </IonChip>
                ))}
              </div>

              <IonChip style={{ overflow: "visible" }} outline>
                {capsule.isPublic ? (
                  <>
                    <IonIcon icon={globeOutline} color="primary" />
                    <IonLabel>public</IonLabel>
                  </>
                ) : (
                  <>
                    <IonIcon icon={lockClosedOutline} />
                    <IonLabel>private</IonLabel>
                  </>
                )}
              </IonChip>
            </IonCol>
          </IonRow>

          <IonRow style={{ flex: 2 }}>
            <IonCol>
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ dynamicBullets: true, el: ".swiper-pagination" }}
                modules={[Pagination]}
                style={{ overflow: "visible" }}
              >
                {capsule.outfits.map((outfit) => (
                  <SwiperSlide
                    key={outfit.id}
                    onClick={() =>
                      history.push(`/main/wardrobe.outfits/${outfit.id}/edit`)
                    }
                  >
                    <OutfitCard outfit={outfit} large />
                  </SwiperSlide>
                ))}
                <div
                  className="swiper-pagination"
                  slot="pagination"
                  style={{ bottom: -20 }}
                />
              </Swiper>
            </IonCol>
          </IonRow>

          <IonRow style={{ flex: 1 }}>
            <IonCol>
              <h1>
                Capsule details: <br />
                {capsule.outfits.length} outfits <br />
                <IonRouterLink
                  onClick={() =>
                    history.push(`/main/wardrobe/capsules/${capsuleId}/items`)
                  }
                >
                  {uniqueItemIds.length} items{" "}
                  <IonIcon
                    icon={airplaneOutline}
                    style={{ verticalAlign: "middle" }}
                  />
                </IonRouterLink>{" "}
              </h1>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonActionSheet
          isOpen={!!actionsVisible}
          buttons={[
            {
              text: "Delete",
              role: "destructive",
              handler: handleDelete,
            },
            {
              text: "Edit",
              handler: handleEdit,
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
          onDidDismiss={() => setActionsVisible(false)}
        />
      </IonContent>
    </IonPage>
  );
};

const DELETE_CAPSULE = gql`
  mutation DeleteCapsule($capsuleId: ID!) {
    deleteCapsule(capsuleId: $capsuleId)
  }
`;

export const CapsulePageFragment = gql`
  fragment CapsulePage on Capsule {
    id
    title
    tags
    isPublic
    outfits {
      id
      items {
        id
      }
      ...OutfitCard
    }
  }
  ${OutfitCardFragment}
`;

export const GET_CAPSULE = gql`
  query CapsulePageQuery($capsuleId: ID!) {
    capsules(capsuleIds: [$capsuleId]) {
      ...CapsulePage
    }
  }
  ${CapsulePageFragment}
`;
export interface Capsule {
  id: string;
  title: string;
  tags: string[];
  isPublic: boolean;
  outfits: OutfitCardData[];
}
