import { gql } from "@apollo/client";
import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonIcon,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";

import { ImageWithPlaceholder } from "./ImageWithPlaceholder";

export const ClothingCard: React.FunctionComponent<{
  item: ClothingCardData;
  children?: React.ReactNode;
  selected?: boolean;
  showLabels?: boolean;
  onClick?: () => void;
}> = ({
  item,
  children = undefined,
  selected = false,
  showLabels = true,
  onClick = () => {},
}) => {
  return (
    <StyledClothingCard
      className={selected ? "selected" : ""}
      onClick={onClick}
      button
    >
      <div style={{ position: "relative" }}>
        {children ? (
          <div style={{ height: 200 }}>{children}</div>
        ) : (
          <ImageWithPlaceholder src={item.imageUrl} style={{ height: 200 }} />
        )}

        {selected && (
          <IonIcon
            icon={checkmarkCircleOutline}
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              fontSize: 30,
              color: "white",
            }}
          />
        )}
      </div>

      {showLabels && (
        <IonCardContent>
          <IonBadge className="ion-float-right" color="light">
            {item.color}
          </IonBadge>
          <IonCardSubtitle slot="end">{item.type}</IonCardSubtitle>
        </IonCardContent>
      )}
    </StyledClothingCard>
  );
};

export const StyledClothingCard = styled(IonCard)`
  margin: 0;
  padding-inline: 0;

  &.selected {
    opacity: 0.5;
  }

  ion-card-content {
    padding: 8px;
  }

  ion-img::part(image),
  img {
    border-radius: 6px;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;

export const ClothingCardFragment = gql`
  fragment ClothingCard on ClothingItem {
    id
    createdAt
    imageUrl
    type
    color
  }
`;

export interface ClothingCardData {
  id: string;
  imageUrl: string;
  type: string;
  color: string;
}
