import React from "react";
import styled from "styled-components";

import { Center } from "./Center";

interface IntroHeaderProps {
  readonly large?: boolean;
}
export const IntroHeader = styled.div<IntroHeaderProps>`
  padding: 24px;
  text-align: center;
  font-family: Caveat, cursive;
  font-size: ${(props) => (props.large ? 72 : 60)}px;
  margin-top: ${(props) => (props.large ? 15 : 25)}%;
`;

export const IntroContent = styled(Center)`
  padding: 4em;
`;

export const IntroArrow: React.FunctionComponent<{
  direction: "bottom" | "right" | "bottom-right";
}> = ({ direction }) => {
  const position: Record<string, unknown> = {};
  if (direction === "bottom") {
    position.left = "calc(50% - 32px)";
    position.bottom = 20;
  } else if (direction === "right") {
    position.right = 80;
    position.bottom = 24;
  } else if (direction === "bottom-right") {
    position.right = 50;
    position.bottom = 80;
  }

  return (
    <img
      src={`/assets/arrow-${direction}.svg`}
      style={{ position: "absolute", width: 200, ...position }}
      alt={`arrow ${direction}`}
    />
  );
};
