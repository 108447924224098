import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle as HapticsImpactStyle } from "@capacitor/haptics";

export { HapticsImpactStyle };

const isAvailable = Capacitor.isPluginAvailable("Haptics");
const noop = () => {};
const SafeHaptics = new Proxy(Haptics, {
  get: (target, prop, receiver) => {
    if (isAvailable) {
      return Reflect.get(target, prop, receiver);
    } else {
      return noop;
    }
  },
});

export function useHaptics() {
  return SafeHaptics;
}
