import { gql, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { uniqBy } from "lodash";
import { useParams } from "react-router";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";

export function ExploreCapsuleItemsPage() {
  const { capsuleId } = useParams<{ capsuleId: string }>();

  const query = useQuery<{ capsules: Capsule[] }>(GET_CAPSULE_ITEMS, {
    variables: { capsuleId },
  });

  const capsule = query.data?.capsules?.[0];
  if (!capsule) return null;

  const uniqueItems = uniqBy(capsule.outfits.map((o) => o.items).flat(), "id");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/main/wardrobe/capsules/${capsuleId}/view`}
            />
          </IonButtons>
          <IonTitle>Items in {capsule.title}</IonTitle>
          <IonButtons slot="end"></IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ClothingList items={uniqueItems} />
      </IonContent>
    </IonPage>
  );
}

export const GET_CAPSULE_ITEMS = gql`
  query CapsulePageQuery($capsuleId: ID!) {
    capsules: publicCapsules(capsuleIds: [$capsuleId]) {
      id
      title
      outfits {
        id
        items {
          id
          ...ClothingListItem
        }
      }
    }
  }
  ${ClothingListItemFragment}
`;
export interface Capsule {
  id: string;
  title: string;
  outfits: {
    id: string;
    items: ClothingListItem[];
  }[];
}
