import { IonContent, IonPage } from "@ionic/react";

import { Center } from "../components/Center";

export const LoginPendingPage = () => {
  return (
    <IonPage>
      <IonContent>
        <Center style={{ lineHeight: 2 }}>
          You should receive an email shortly.
          <br />
          Please click the one-time login link there.
        </Center>
      </IonContent>
    </IonPage>
  );
};
