import { useStorageItem } from "@capacitor-community/storage-react";
import {
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonSpinner,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { Center } from "../components/Center";
import { useAuth } from "../hooks/useAuth";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LoginPage = () => {
  const auth = useAuth();
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [storedEmail, setStoredEmail] = useStorageItem<string>("stored_email");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(storedEmail ?? "");
  }, [storedEmail, setEmail]);

  useIonViewWillEnter(() => {
    setLoading(false);
  }, [setLoading]);

  const isValidEmail = !!email.match(EMAIL_REGEX);

  const handleSubmit = async () => {
    setLoading(true);
    setStoredEmail(email);
    // TODO: why does executing the mutation also run other unrelated queries, which are unauthenticated and therefor trigger logout?
    // Below works around that temporarily by not using apollo-client at all.
    await axios
      .post(process.env.REACT_APP_GRAPHQL_URL as string, {
        operationName: "LoginMutation",
        query: `
          mutation LoginMutation($email: String!) {
            initLogin(email: $email) {
              ... on LoginPending {
                __typename
                sessionId
              }
              ... on UserTokens {
                __typename
                accessToken
              }
            }
          }
      `,
        variables: { email },
      })
      .then(async ({ data }) => {
        const res = data.data?.initLogin || {};
        if (res.__typename === "UserTokens") {
          await auth.login(res.accessToken);
        } else {
          history.push("/login/pending");
        }
      });
  };

  return (
    <IonPage>
      <IonContent>
        {loading ? (
          <Center>
            <IonSpinner />
          </Center>
        ) : (
          <StyledContainer>
            <StyledIonInput
              type="email"
              inputmode="email"
              autocomplete="email"
              placeholder="your@email.com"
              onIonChange={(e) => setEmail(e.detail.value!)}
              value={email}
            />
            <IonButton
              expand="block"
              fill="outline"
              disabled={!isValidEmail}
              onClick={handleSubmit}
            >
              Continue
            </IonButton>
          </StyledContainer>
        )}
      </IonContent>
    </IonPage>
  );
};

const StyledContainer = styled(Center)`
  padding: 4em;
`;

const StyledIonInput = styled(IonInput)`
  border-bottom: 2px solid black !important;
  margin-bottom: 2em;
`;
