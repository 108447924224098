import { gql, useMutation } from "@apollo/client";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonText,
} from "@ionic/react";
import { useState } from "react";

import { FloatingButton } from "../components/FloatingButton";

const HANDLE_REGEX = /^[a-zA-Z0-9_-]*$/;

export const UserSetupPage = () => {
  const [handle, setHandle] = useState<string>("");

  const [setupMutation] = useMutation(SETUP_USER_MUTATION);

  const handleSubmit = async () => {
    await setupMutation({ variables: { handle } });
  };

  return (
    <IonPage>
      <IonModal animated={false} isOpen>
        <div style={{ position: "absolute", top: "35%", width: "100%" }}>
          <IonItem style={{ fontSize: 28 }}>
            <IonLabel position="floating">Choose a username</IonLabel>
            <IonInput
              type="text"
              value={handle}
              onIonChange={(e) => setHandle(e.detail.value!)}
              maxlength={20}
            />
          </IonItem>
          {!handle.match(HANDLE_REGEX) && (
            <IonItem lines="none">
              <IonText color="danger">
                Invalid format, may only contain letters, numbers, hyphen or
                underscores
              </IonText>
            </IonItem>
          )}
        </div>

        {handle.length >= 3 && (
          <FloatingButton onClick={handleSubmit} style={{ position: "fixed" }}>
            Continue
          </FloatingButton>
        )}
      </IonModal>
    </IonPage>
  );
};

const SETUP_USER_MUTATION = gql`
  mutation SetupUserMutation($handle: String!) {
    setupUser(handle: $handle) {
      id
      handle
    }
  }
`;
