import "./ClothingForm.css";

import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonProgressBar,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import upperFirst from "lodash/upperFirst";
import React from "react";
import { useCallback, useEffect } from "react";

import { ColorSelection } from "../../components/ColorSelection";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { HapticsImpactStyle, useHaptics } from "../../hooks/useHaptics";
import { PhotoUpload } from "../../hooks/usePhotoUpload";

const COLORS: Record<string, string> = {
  black: "#000000",
  white: "#ffffff",
  grey: "#808080",
  beige: "#cfb997",
  brown: "#8b4513",
  red: "#ff0000",
  orange: "#ff8000",
  yellow: "#ffff00",
  lightGreen: "#99ff99",
  darkGreen: "#19721E",
  lightBlue: "#99ccff",
  darkBlue: "#0000ff",
  purple: "#8000ff",
  pink: "#ff0080",
};

// const COLORS: Record<string, string> = {
//   // special colors
//   black: "#000000",
//   white: "#ffffff",
//   grey: "#808080",
//   // color wheel colors
//   red: "#ff0000",
//   orange: "#ff8000",
//   yellow: "#ffff00",
//   springGreen: "#80ff00", // spring green (green1)
//   green: "#00ff00", // green (green2)
//   turquoise: "#00ff80", // turquoise (green3)
//   cyan: "#00ffff", // cyan (blue1)
//   ocean: "#0080ff", // ocean (blue2)
//   blue: "#0000ff", // blue (blue3)
//   violet: "#8000ff", // violet (purple)
//   magenta: "#ff00ff", // magenta (pink1)
//   raspberry: "#ff0080", // raspberry (pink2)
// };

const TYPES: { [key: string]: string[] } = {
  top: ["t-shirt", "blouse", "shirt", "jumper", "sweater", "dress", "pyjamas", "loungewear", "activewear"],
  bottom: ["jeans", "trousers", "skirt", "shorts", "pyjamas", "loungewear", "activewear"],
  outer: ["coat", "jacket"],
  shoes: ["sneakers", "loafers", "heels", "runners"]
};

export const CATEGORIES = Object.keys(TYPES);

export interface ClothingFormState {
  category: string;
  type?: string;
  color?: string;
  imageKey?: string;
}

export const ClothingForm: React.FC<{
  state: ClothingFormState | undefined;
  setState: Function;
  photoUpload: PhotoUpload;
  isComplete: boolean;
  handleSubmit: () => void;
}> = ({ state, setState, photoUpload, isComplete, handleSubmit }) => {
  const haptics = useHaptics();

  const updateState = useCallback(
    (
      key: keyof ClothingFormState,
      value: string | undefined,
      withFeedback = true
    ) => {
      setState((prevState: ClothingFormState) => ({
        ...prevState,
        [key]: value,
      }));

      if (withFeedback) {
        haptics.impact({ style: HapticsImpactStyle.Light });
      }
    },
    [setState, haptics]
  );

  useEffect(() => {
    updateState("imageKey", photoUpload.imageKey, false);
  }, [photoUpload.imageKey, updateState]);

  if (!state) return null;

  return (
    <>
      <IonGrid id="clothing-form">
        <IonRow>
          <IonCol>
            <ImageWithPlaceholder
              src={photoUpload.imagePreview}
              onClick={() => photoUpload.takePhoto().catch(console.error)}
            />
            {photoUpload.uploading && (
              <IonProgressBar type="indeterminate"></IonProgressBar>
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSegment
              value={state.category}
              onIonChange={(e) => {
                updateState("category", e.detail.value!);
                updateState("type", undefined, false);
              }}
              scrollable
            >
              {CATEGORIES.map((category) => (
                <IonSegmentButton key={category} value={category}>
                  {upperFirst(category)}{" "}
                </IonSegmentButton>
              ))}
            </IonSegment>
          </IonCol>
        </IonRow>
        {!state.type ? (
          <IonRow>
            <IonCol>
              {TYPES[state.category].map((type) => (
                <IonChip
                  key={type}
                  onClick={() => updateState("type", type)}
                  outline={type !== state.type}
                >
                  {upperFirst(type)}
                </IonChip>
              ))}
            </IonCol>
          </IonRow>
        ) : (
          <IonChip onClick={() => updateState("type", undefined)}>
            {upperFirst(state.type)}
          </IonChip>
        )}

        {!state.color ? (
          <IonRow>
            <IonCol>
              <ColorSelection
                colors={Object.keys(COLORS).map((key) => ({
                  key,
                  hex: COLORS[key],
                }))}
                onItemClick={(color) => updateState("color", color.key)}
              />
            </IonCol>
          </IonRow>
        ) : (
          <ColorSelection
            colors={[{ key: state.color, hex: COLORS[state.color] }]}
            onItemClick={() => updateState("color", undefined)}
          />
        )}

        <IonRow>
          <IonCol>
            <IonButton
              onClick={handleSubmit}
              disabled={!isComplete}
              expand="block"
            >
              Done
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};
