import { gql, useQuery } from "@apollo/client";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { arrowDownOutline, personCircleOutline } from "ionicons/icons";
import { useHistory } from "react-router-dom";

import {
  CapsuleList,
  CapsuleListItem,
  CapsuleListItemFragment,
} from "../../components/CapsuleList";

export const ExploreListPage = () => {
  const history = useHistory();

  const query = useQuery<ExplorePageQueryResult>(EXPLORE_PAGE_QUERY);
  const capsules = query.data?.publicCapsules ?? [];

  const handleRefresh = async (e: CustomEvent<RefresherEventDetail>) => {
    await query.refetch();
    e.detail.complete();
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Explore</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={personCircleOutline}
              onClick={() => history.push("/main/profile")}
              style={{ marginRight: 4 }}
              slot="icon-only"
              color="primary"
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent
            pullingIcon={arrowDownOutline}
            refreshingSpinner="circles"
          />
        </IonRefresher>

        <CapsuleList
          items={capsules}
          onItemClick={(capsule) =>
            history.push(`/main/explore/capsules/${capsule.id}`)
          }
          showOwner
          showImported
          showPublic={false}
        />
      </IonContent>
    </IonPage>
  );
};

const EXPLORE_PAGE_QUERY = gql`
  query ExplorePageQuery {
    publicCapsules {
      ...CapsuleListItem
    }
  }
  ${CapsuleListItemFragment}
`;
interface ExplorePageQueryResult {
  publicCapsules: CapsuleListItem[];
}
