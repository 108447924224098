import { gql, useMutation } from "@apollo/client";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { usePhotoUpload } from "../../hooks/usePhotoUpload";
import { ClothingForm, ClothingFormState } from "./ClothingForm";

const DEFAULT_CLOTHING_FORM_STATE = {
  category: "top",
};

export const ClothingAddPage = () => {
  const history = useHistory();
  const photoUpload = usePhotoUpload();

  const [processing, setProcessing] = useState(false);
  const [state, setState] = useState<ClothingFormState>(
    DEFAULT_CLOTHING_FORM_STATE
  );

  const [addClothingItem] = useMutation(ADD_CLOTHING_ITEM, {
    refetchQueries: ["ClothingListPageQuery"],
  });

  useIonViewWillEnter(() => {
    photoUpload.takePhoto().catch((err) => {
      console.error(err);
      history.replace("/main/wardrobe/clothing");
    });
  });

  const handleSubmit = async () => {
    if (processing) return;
    setProcessing(true);
    await addClothingItem({ variables: state });
    setProcessing(false);
    setState(DEFAULT_CLOTHING_FORM_STATE);
    history.push("/main/wardrobe/clothing");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe/clothing" />
          </IonButtons>
          <IonTitle>Fill in item information</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ClothingForm
          state={state}
          setState={setState}
          photoUpload={photoUpload}
          isComplete={!!state.imageKey && !!state.type && !!state.color}
          handleSubmit={handleSubmit}
        />
      </IonContent>
    </IonPage>
  );
};

const ADD_CLOTHING_ITEM = gql`
  mutation AddClothingItem(
    $imageKey: String!
    $category: String!
    $type: String!
    $color: String!
  ) {
    addClothingItem(
      imageKey: $imageKey
      category: $category
      type: $type
      color: $color
    ) {
      id
    }
  }
`;
