import { gql } from "@apollo/client";
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import orderBy from "lodash/orderBy";
import React, { useMemo, useState } from "react";

import { Center } from "./Center";
import {
  ClothingCard,
  ClothingCardData,
  ClothingCardFragment,
  StyledClothingCard,
} from "./ClothingCard";

export const ClothingList: React.FunctionComponent<{
  items: ClothingListItem[];
  selectedItems?: ClothingListItem[];
  onItemClick?: (item: ClothingListItem) => void;
  onAddClick?: Function;
  showAddButton?: boolean;
}> = ({
  items,
  selectedItems = [],
  onItemClick = () => {},
  onAddClick = () => {},
  showAddButton = false,
}) => {
  const [count, setCount] = useState(10);
  const onInfinite = (e: any) => {
    setCount((count) => count + 4);
    e.target.complete();
  };

  const orderedItems = useMemo(
    () => orderBy(items, "createdAt", "desc"),
    [items]
  );

  return (
    <IonGrid className="clothing-list">
      <IonRow>
        {orderedItems.slice(0, count).map((item, i) => (
          <IonCol size="6" key={item.id}>
            <ClothingCard
              item={item}
              selected={selectedItems.includes(item)}
              onClick={() => onItemClick(item)}
            />
          </IonCol>
        ))}
        {showAddButton && (
          <IonCol size="6">
            <StyledClothingCard
              style={{ height: 236 }}
              onClick={() => onAddClick()}
              button
            >
              <Center style={{ fontSize: 36 }}>
                <IonIcon icon={addCircleOutline} />
              </Center>
            </StyledClothingCard>
          </IonCol>
        )}
      </IonRow>

      <IonInfiniteScroll
        onIonInfinite={onInfinite}
        threshold="100px"
        disabled={count >= items.length}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </IonGrid>
  );
};

export const ClothingListItemFragment = gql`
  fragment ClothingListItem on ClothingItem {
    id
    ...ClothingCard
  }
  ${ClothingCardFragment}
`;

export interface ClothingListItem extends ClothingCardData {
  id: string;
}
