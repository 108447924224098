import { gql, useQuery } from "@apollo/client";
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
} from "@ionic/react";
import { add, earthOutline } from "ionicons/icons";
import orderBy from "lodash/orderBy";
import { useHistory, Link } from "react-router-dom";
import { IntroArrow, IntroContent, IntroHeader } from "../../components/Intro";

import {
  CapsuleCard,
  CapsuleCardData,
  CapsuleCardFragment,
} from "../../components/CapsuleCard";
import { WardrobeNavigation } from "../../components/WardrobeNavigation";

export const CapsuleListPage = () => {
  const history = useHistory();

  const query = useQuery<CapsuleListPageQueryResult>(CAPSULE_LIST_PAGE_QUERY);
  const capsules = orderBy(query.data?.capsules ?? [], "createdAt", "desc");

  return (
    <IonPage>
      <IonContent className="safe-area">
        {capsules.map((capsule) => (
          <CapsuleCard
            key={capsule.id}
            capsule={capsule}
            onClick={() =>
              history.push(`/main/wardrobe/capsules/${capsule.id}/view`)
            }
          />
        ))}

        {!query.loading && capsules.length === 0 && (
          <>
            <IntroHeader>Create capsule</IntroHeader>
            <IntroContent>
              <p>Now, create a capsule by pressing the plus button below.</p>
              <p>
                Tip: Get inspired by browsing and importing public capsule on
                the{" "}
                <Link
                  to="/main/explore"
                  style={{ whiteSpace: "nowrap", textDecoration: "none" }}
                >
                  Explore Page <IonIcon icon={earthOutline} />
                </Link>
              </p>
            </IntroContent>
            <IntroArrow direction="bottom-right" />
          </>
        )}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink="/main/wardrobe/capsules/create">
            <IonIcon icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <WardrobeNavigation />
      </IonContent>
    </IonPage>
  );
};

const CAPSULE_LIST_PAGE_QUERY = gql`
  query CapsuleListPageQuery {
    capsules {
      id
      createdAt
      ...CapsuleCard
    }
  }
  ${CapsuleCardFragment}
`;
interface CapsuleListPageQueryResult {
  capsules: CapsuleCardData[];
}
