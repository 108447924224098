import { useStorageItem } from "@capacitor-community/storage-react";
import jwtDecode from "jwt-decode";
import React from "react";

export const AuthContext = React.createContext<AuthContextValue>({} as any);

export const LOGGED_OUT = "LOGGED_OUT";

export const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [token, setToken] = useStorageItem<string | undefined>(
    "access_token",
    LOGGED_OUT
  );

  let payload: TokenPayload | undefined = undefined;
  if (token && token !== LOGGED_OUT) {
    try {
      payload = jwtDecode(token);
    } catch (err) {
      console.error(err);
    }
  }

  const value: AuthContextValue = {
    accessToken: token,
    payload,
    setToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

interface TokenPayload {
  id: number;
  exp: number;
}

interface AuthContextValue {
  setToken: (accessToken?: string) => Promise<void>;
  accessToken?: string;
  payload?: TokenPayload;
}
