import { gql, useQuery } from "@apollo/client";
import { IonSegment, IonSegmentButton } from "@ionic/react";
import { countBy, groupBy, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";
import { FloatingButton } from "../../components/FloatingButton";
import { CATEGORIES } from "../clothing/ClothingForm";
import { Capsule } from "./CapsulePage";

const EMPTY_ARRAY: any[] = [];

export const CapsuleForm = ({ capsule }: { capsule?: Capsule }) => {
  const history = useHistory();
  const [selectedCategory, setCategory] = useState<string>(CATEGORIES[0]);
  const [selectedItems, setSelectedItems] = useState<ClothingListItem[]>([]);

  const query = useQuery<QueryResult>(FORM_QUERY);
  const items = query.data?.clothingItems ?? EMPTY_ARRAY;
  const groupedItems = groupBy(items, "category");
  const groupedSelectionCounts = countBy(selectedItems, "category");
  const hasEnoughSelected = Object.keys(groupedSelectionCounts).length >= 2;

  // Preselect items already in capsule (edit mode)
  useEffect(() => {
    if (capsule && items.length) {
      const capsuleItemIds: string[] = [];
      for (const outfit of capsule.outfits) {
        for (const item of outfit.items) {
          if (!capsuleItemIds.includes(item.id)) {
            capsuleItemIds.push(item.id);
          }
        }
      }
      setSelectedItems(
        items.filter((item) => capsuleItemIds.includes(item.id))
      );
    }
  }, [capsule, items]);

  const toggleItemSelection = (item: ClothingListItem) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSubmit = async () => {
    // generate combinations
    function cartesian(...a: Array<any>) {
      return a.reduce((a: any, b: Array<any>) =>
        a.flatMap((d: any) => b.map((e) => [d, e].flat()))
      );
    }
    const combinations: Array<ClothingListItem[]> = cartesian(
      ...Object.values(groupBy(selectedItems, "category"))
    );

    // reset state
    // setCategory(CATEGORIES[0]);
    // setSelectedItems([]);

    // navigate to outfits list
    history.push(history.location.pathname + "/complete", { combinations });
  };

  return (
    <>
      <IonSegment
        value={selectedCategory}
        onIonChange={(e) => setCategory(e.detail.value!)}
      >
        {CATEGORIES.map((category) => (
          <IonSegmentButton key={category} value={category}>
            {upperFirst(category)}{" "}
            {groupedSelectionCounts[category] && (
              <>({groupedSelectionCounts[category]})</>
            )}
          </IonSegmentButton>
        ))}
      </IonSegment>
      <ClothingList
        items={groupedItems[selectedCategory] ?? []}
        selectedItems={selectedItems}
        onItemClick={toggleItemSelection}
      />
      <FloatingButton onClick={handleSubmit} disabled={!hasEnoughSelected}>
        Preview outfits
      </FloatingButton>
    </>
  );
};

const FORM_QUERY = gql`
  query CapsuleFormQuery {
    clothingItems {
      id
      category
      ...ClothingListItem
    }
  }
  ${ClothingListItemFragment}
`;

interface QueryResult {
  clothingItems: ClothingListItem[];
}
