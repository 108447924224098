import { gql, useMutation, useQuery } from "@apollo/client";
import {
  IonActionSheet,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { ellipsisHorizontal } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { usePhotoUpload } from "../../hooks/usePhotoUpload";
import { ClothingForm, ClothingFormState } from "./ClothingForm";

export const ClothingEditPage = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const history = useHistory();
  const photoUpload = usePhotoUpload();

  const [actionsVisible, setActionsVisible] = useState(false);
  const [state, setState] = useState<ClothingFormState>();

  const [editMutation] = useMutation(EDIT_CLOTHING_ITEM);
  const [deleteMutation] = useMutation(DELETE_CLOTHING_ITEM, {
    variables: { itemId },
    refetchQueries: ["ClothingListPageQuery"],
  });

  const itemsQuery = useQuery(GET_CLOTHING_ITEM, { variables: { itemId } });
  const item = itemsQuery.data?.clothingItems?.[0];

  useEffect(() => {
    if (!item) return;
    setState({ category: item.category, type: item.type, color: item.color });
  }, [item, setState]);

  const handleSubmit = async () => {
    await editMutation({
      variables: { itemId, imageKey: photoUpload.imageKey, ...state },
    });
    history.push("/main/wardrobe/clothing");
  };

  const handleDelete = async () => {
    await deleteMutation();
    history.push("/main/wardrobe/clothing");
  };

  if (!item) return null;

  return (
    <IonPage id="page-clothing-add">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe/clothing" />
          </IonButtons>
          <IonTitle>Edit clothing item</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={ellipsisHorizontal}
              color="primary"
              slot="icon-only"
              onClick={() => setActionsVisible(true)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ClothingForm
          state={state}
          setState={setState}
          photoUpload={{
            ...photoUpload,
            imagePreview: photoUpload.imagePreview ?? item.imageUrl,
          }}
          isComplete={!!state?.category && !!state?.type && !!state?.color}
          handleSubmit={handleSubmit}
        />
        <IonActionSheet
          isOpen={!!actionsVisible}
          buttons={[
            {
              text: "Delete",
              role: "destructive",
              handler: handleDelete,
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
          onDidDismiss={() => setActionsVisible(false)}
        />
      </IonContent>
    </IonPage>
  );
};

const ClothingItemFragment = gql`
  fragment ClothingItemFragment on ClothingItem {
    id
    imageUrl
    category
    type
    color
  }
`;

const GET_CLOTHING_ITEM = gql`
  query GetClothingItem($itemId: ID!) {
    clothingItems(itemIds: [$itemId]) {
      ...ClothingItemFragment
    }
  }
  ${ClothingItemFragment}
`;

const DELETE_CLOTHING_ITEM = gql`
  mutation DeleteClothingItem($itemId: ID!) {
    deleteClothingItem(itemId: $itemId)
  }
`;

const EDIT_CLOTHING_ITEM = gql`
  mutation EditClothingItem(
    $itemId: ID!
    $imageKey: String
    $category: String!
    $type: String!
    $color: String!
  ) {
    editClothingItem(
      itemId: $itemId
      imageKey: $imageKey
      category: $category
      type: $type
      color: $color
    ) {
      ...ClothingItemFragment
    }
  }
  ${ClothingItemFragment}
`;
