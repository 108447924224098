import { gql, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";

import { FloatingButton } from "../../components/FloatingButton";
import {
  OutfitList,
  OutfitListItem,
  OutfitListItemFragment,
} from "../../components/OutfitList";

export const ExploreImportPage: React.FC = () => {
  const history = useHistory();
  const { capsuleId } = useParams<{ capsuleId: string }>();
  const [selectedOutfits, setSelectedOutfits] = useState<OutfitListItem[]>([]);

  const query = useQuery<ExploreImportQueryResult>(EXPLORE_IMPORT_QUERY, {
    variables: { capsuleId },
  });

  const capsule = query.data?.publicCapsules?.[0];
  if (!capsule) return null;

  const toggleOutfitSelection = (outfit: OutfitListItem) => {
    if (selectedOutfits.includes(outfit)) {
      setSelectedOutfits(selectedOutfits.filter((x) => x !== outfit));
    } else {
      setSelectedOutfits([...selectedOutfits, outfit]);
    }
  };

  const isComplete = selectedOutfits.length > 0;

  const handleSubmit = () => {
    if (!isComplete) return;
    history.push(history.location.pathname + "/items", {
      outfitIds: selectedOutfits.map((x) => x.id),
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={`/main/explore/capsules/${capsuleId}`}
            />
          </IonButtons>
          <IonTitle>select outfits to import</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <OutfitList
          items={capsule.outfits}
          selectedItems={selectedOutfits}
          onItemClick={toggleOutfitSelection}
        />

        <FloatingButton disabled={!isComplete} onClick={handleSubmit}>
          Continue
        </FloatingButton>
      </IonContent>
    </IonPage>
  );
};

const EXPLORE_IMPORT_QUERY = gql`
  query ExploreImportQuery($capsuleId: ID!) {
    publicCapsules(capsuleIds: [$capsuleId]) {
      outfits {
        ...OutfitListItem
      }
    }
  }
  ${OutfitListItemFragment}
`;
interface ExploreImportQueryResult {
  publicCapsules: {
    outfits: OutfitListItem[];
  }[];
}
