import { gql, useMutation, useQuery } from "@apollo/client";
import {
  IonActionSheet,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { cameraOutline, ellipsisHorizontal } from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";

import { Center } from "../../components/Center";
import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";
import { ImageWithPlaceholder } from "../../components/ImageWithPlaceholder";
import { usePhotoUpload } from "../../hooks/usePhotoUpload";
import { AddItemModal } from "./AddItemModal";

export const OutfitEditPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { outfitId } = useParams<{ outfitId: string }>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [outfitActionsVisible, setOutfitActionsVisible] = useState(false);
  const [imageActionsVisible, setImageActionsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ClothingListItem>();
  const photoUpload = usePhotoUpload();

  const [addItemMutation] = useMutation(ADD_ITEM);
  const [removeItemMutation] = useMutation(REMOVE_ITEM);
  const [editOutfitMutation] = useMutation(EDIT_OUTFIT);
  const [deleteOutfitMutation] = useMutation(DELETE_OUTFIT, {
    variables: { outfitId },
    update: (cache) => {
      cache.modify({
        fields: {
          outfits(existingOutfits: { __ref: string }[] = []) {
            return existingOutfits.filter(
              (x) => x.__ref !== `Outfit:${outfitId}`
            );
          },
        },
      });
    },
  });

  const outfitsQuery = useQuery<OutfitEditPageQueryResult>(GET_OUTFIT, {
    variables: { outfitId },
  });

  useEffect(() => {
    if (photoUpload.imageKey) {
      editOutfitMutation({
        variables: { outfitId, imageKey: photoUpload.imageKey },
      }).then(photoUpload.reset);
    }
  }, [photoUpload.imageKey, photoUpload.reset, outfitId, editOutfitMutation]);

  const outfit = outfitsQuery.data?.outfits?.[0];
  if (!outfit) return null;

  const handleDeleteImage = async () => {
    await editOutfitMutation({
      variables: { outfitId: outfit.id, imageKey: null },
      optimisticResponse: {
        editOutfit: {
          __typename: "Outfit",
          id: outfit.id,
          imageUrl: null,
        },
      },
    });
  };

  const handleDeleteOutfit = async () => {
    await deleteOutfitMutation();
    history.replace("/main/wardrobe.outfits");
  };

  const handleAddItem = async (itemId: string) => {
    await addItemMutation({ variables: { outfitId, itemId } });
    setShowAddModal(false);
  };

  const handleRemoveItem = async () => {
    if (!selectedItem) return;
    await removeItemMutation({
      variables: { outfitId, itemId: selectedItem.id },
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe.outfits" />
          </IonButtons>
          <IonTitle>Outfit</IonTitle>
          <IonButtons slot="end">
            <IonIcon
              icon={ellipsisHorizontal}
              color="primary"
              slot="icon-only"
              style={{ marginRight: 8 }}
              onClick={() => setOutfitActionsVisible(true)}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {photoUpload.imagePreview || outfit.imageUrl ? (
          <ImageWithPlaceholder
            src={photoUpload.imagePreview ?? outfit.imageUrl}
            onClick={() => setImageActionsVisible(true)}
          />
        ) : (
          <IonCard
            style={{ height: 150, margin: 10 }}
            onClick={() => photoUpload.takePhoto().catch(console.error)}
            button
          >
            <Center style={{ fontSize: 36 }}>
              <IonIcon icon={cameraOutline} />
            </Center>
          </IonCard>
        )}
        {photoUpload.uploading && (
          <IonProgressBar type="indeterminate"></IonProgressBar>
        )}

        <ClothingList
          items={outfit.items}
          selectedItems={selectedItem ? [selectedItem] : []}
          onItemClick={(item) => setSelectedItem(item)}
          onAddClick={() => setShowAddModal(true)}
          showAddButton
        />

        <IonActionSheet
          isOpen={outfitActionsVisible}
          buttons={[
            {
              text: "Delete outfit",
              role: "destructive",
              handler: handleDeleteOutfit,
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
          onDidDismiss={() => setOutfitActionsVisible(false)}
        />

        <IonActionSheet
          isOpen={imageActionsVisible}
          buttons={[
            {
              text: "Delete image",
              role: "destructive",
              handler: handleDeleteImage,
            },
            {
              text: "Take new image",
              handler: () => photoUpload.takePhoto().catch(console.error),
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
          onDidDismiss={() => setOutfitActionsVisible(false)}
        />

        <IonActionSheet
          isOpen={!!selectedItem}
          buttons={[
            {
              text: "Remove item",
              role: "destructive",
              handler: handleRemoveItem,
            },
            {
              text: "Cancel",
              role: "cancel",
            },
          ]}
          onDidDismiss={() => setSelectedItem(undefined)}
        />

        <AddItemModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          excludeItemIds={outfit.items.map((x) => x.id)}
          onItemClick={(item) => handleAddItem(item.id)}
        />
      </IonContent>
    </IonPage>
  );
};

const GET_OUTFIT = gql`
  query OutfitEditPageQuery($outfitId: ID!) {
    outfits(outfitIds: [$outfitId]) {
      id
      imageUrl
      items {
        ...ClothingListItem
      }
    }
  }
  ${ClothingListItemFragment}
`;
interface Outfit {
  id: string;
  imageUrl?: string;
  items: ClothingListItem[];
}
interface OutfitEditPageQueryResult {
  outfits: Outfit[];
}

const DELETE_OUTFIT = gql`
  mutation DeleteOutfit($outfitId: ID!) {
    deleteOutfit(outfitId: $outfitId)
  }
`;

const EDIT_OUTFIT = gql`
  mutation EditOutfit($outfitId: ID!, $imageKey: String) {
    editOutfit(outfitId: $outfitId, imageKey: $imageKey) {
      id
      imageUrl
    }
  }
`;

const ADD_ITEM = gql`
  mutation AddItemToOutfit($outfitId: ID!, $itemId: ID!) {
    addItemToOutfit(outfitId: $outfitId, itemId: $itemId) {
      id
      items {
        ...ClothingListItem
      }
    }
  }
  ${ClothingListItemFragment}
`;

const REMOVE_ITEM = gql`
  mutation RemoveItemFromOutfit($outfitId: ID!, $itemId: ID!) {
    removeItemFromOutfit(outfitId: $outfitId, itemId: $itemId) {
      id
      items {
        ...ClothingListItem
      }
    }
  }
  ${ClothingListItemFragment}
`;
