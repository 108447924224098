import { gql } from "@apollo/client";
import { IonCard, IonCardHeader, IonIcon } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import React from "react";
import styled from "styled-components";

export const OutfitCard: React.FunctionComponent<{
  outfit: OutfitCardData;
  selected?: boolean;
  showOwner?: boolean;
  plain?: boolean;
  large?: boolean;
  onClick?: () => void;
}> = ({
  outfit,
  selected = false,
  showOwner = false,
  plain = false,
  large = false,
  onClick = () => {},
}) => {
  return (
    <StyledOutfitCard
      onClick={onClick}
      plain={plain}
      selected={selected}
      large={large}
    >
      {showOwner && <IonCardHeader>{outfit.owner.handle}</IonCardHeader>}
      <div>
        {outfit.imageUrl ? (
          <img src={outfit.imageUrl} alt="" />
        ) : (
          outfit.items.map((item, i) => (
            <img key={i} src={item.imageUrl} alt="" />
          ))
        )}
      </div>
      {selected && (
        <IonIcon
          icon={checkmarkCircleOutline}
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
            fontSize: 30,
            color: "white",
          }}
        />
      )}
    </StyledOutfitCard>
  );
};

interface StyledOutfitCardProps {
  readonly plain?: boolean;
  readonly selected?: boolean;
  readonly large?: boolean;
}
export const StyledOutfitCard = styled(IonCard)<StyledOutfitCardProps>`
  margin: 0;
  padding-inline: 0;
  white-space: nowrap;
  overflow-x: scroll;
  box-shadow: ${(props) => (props.plain ? "none" : "default")};
  opacity: ${(props) => (props.selected ? 0.5 : 1)};

  ion-card-content,
  ion-card-header {
    display: flex;
    align-items: center;
    padding: 8px;
  }

  ion-card-content ion-chip {
    margin-top: 0;
    margin-bottom: 0;
    overflow: visible;
  }

  img {
    border-radius: 4px;
    height: ${(props) => (props.large ? "400px" : "200px")};
    object-fit: cover;
    width: 56%;

    &:only-child {
      width: 100%;
    }

    &:not(:first-child) {
      margin-left: -12%;
    }

    &:first-child:not(:last-child) {
      clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    }

    &:not(:first-child):not(:last-child) {
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    }

    &:last-child:not(:first-child) {
      clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
`;

export const OutfitCardFragment = gql`
  fragment OutfitCard on Outfit {
    id
    imageUrl
    owner {
      id
      handle
    }
    items {
      id
      imageUrl
    }
  }
`;

export interface OutfitCardData {
  id: string;
  imageUrl?: string;
  owner: { id: string; handle: string };
  items: { id: string; imageUrl: string }[];
}
