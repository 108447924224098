import { gql, useMutation } from "@apollo/client";
import { CameraResultType, CameraSource } from "@capacitor/camera";
import { useCamera } from "@capacitor-community/camera-react";
import axios from "axios";
import { useCallback, useState } from "react";

export function usePhotoUpload() {
  const [uploading, setUploading] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const [key, setKey] = useState<string>();

  const [prepareImageUpload] = useMutation(PREPAGE_IMAGE_UPLOAD);

  const { getPhoto } = useCamera();

  const takePhoto = async () => {
    const photo = await getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Prompt,
      quality: 90,
    });
    const contentType = "image/" + photo.format;
    const base64String = await resizeImage(photo.base64String!, contentType);
    setImage(`data:${contentType};base64,${base64String}`);

    setKey(undefined);
    setUploading(true);
    const preparedUpload: PreparedUpload = await prepareImageUpload({
      variables: { contentType },
    }).then((res) => res.data.prepareImageUpload);

    await axios.put(
      preparedUpload.presignedUrl,
      Buffer.from(base64String!, "base64"),
      { headers: { "Content-Type": contentType } }
    );
    setKey(preparedUpload.key);
    setUploading(false);
  };

  const reset = useCallback(() => {
    setImage(undefined);
    setKey(undefined);
    setUploading(false);
  }, [setImage, setKey, setUploading]);

  return {
    imagePreview: image,
    imageKey: key,
    uploading,
    takePhoto,
    reset,
  };
}

export async function resizeImage(
  base64Source: string,
  contentType: string = "image/jpeg"
): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // // Resizing to the nearest base-2 division to the target height.
      // const [origin, target] = [img.naturalHeight, 600];
      // canvas.height = origin / Math.max(Math.floor(origin / target / 2) * 2, 1);
      canvas.height = 600;
      canvas.width = canvas.height * (img.naturalWidth / img.naturalHeight);

      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
      const dataUrl = canvas.toDataURL(contentType);
      const base64Resized = dataUrl.split(`data:${contentType};base64,`)[1];
      resolve(base64Resized);
    };
    img.src = `data:${contentType};base64,${base64Source}`;
  });
}

export type PhotoUpload = ReturnType<typeof usePhotoUpload>;

const PREPAGE_IMAGE_UPLOAD = gql`
  mutation PrepareImageUploadMutation($contentType: String!) {
    prepareImageUpload(contentType: $contentType) {
      presignedUrl
      key
    }
  }
`;
interface PreparedUpload {
  presignedUrl: string;
  key: string;
}
