import { gql } from "@apollo/client";
import {
  IonCol,
  IonGrid,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from "@ionic/react";
import orderBy from "lodash/orderBy";
import React, { useMemo, useState } from "react";

import {
  CapsuleCard,
  CapsuleCardData,
  CapsuleCardFragment,
} from "./CapsuleCard";

export const CapsuleList: React.FunctionComponent<{
  items: CapsuleListItem[];
  showOwner?: boolean;
  showPublic?: boolean;
  showImported?: boolean;
  onItemClick?: (capsule: CapsuleListItem) => void;
}> = ({
  items,
  showOwner,
  showPublic,
  showImported,
  onItemClick = () => {},
}) => {
  const [count, setCount] = useState(4);
  const onInfinite = (e: any) => {
    setCount((count) => count + 2);
    e.target.complete();
  };

  const orderedItems = useMemo(
    () => orderBy(items, "createdAt", "desc"),
    [items]
  );

  return (
    <IonGrid>
      {orderedItems.slice(0, count).map((capsule) => (
        <IonRow key={capsule.id}>
          <IonCol>
            <CapsuleCard
              capsule={capsule}
              showOwner={showOwner}
              showPublic={showPublic}
              showImported={showImported}
              onClick={() => onItemClick(capsule)}
            />
          </IonCol>
        </IonRow>
      ))}

      <IonInfiniteScroll
        onIonInfinite={onInfinite}
        threshold="100px"
        disabled={count >= items.length}
      >
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </IonGrid>
  );
};

export const CapsuleListItemFragment = gql`
  fragment CapsuleListItem on Capsule {
    id
    ...CapsuleCard
  }
  ${CapsuleCardFragment}
`;

export interface CapsuleListItem extends CapsuleCardData {
  id: string;
}
