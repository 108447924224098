import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Center } from "../components/Center";
import { useAuth } from "../hooks/useAuth";

interface Params {
  sessionId: string;
  otpToken: string;
}

export const AuthPage = () => {
  const [error, setError] = useState<string>();

  const auth = useAuth();
  const params = useParams<Params>();
  const [loginMutation] = useMutation(LOGIN_MUTATION);

  useEffect(() => {
    loginMutation({ variables: params })
      .then(({ data }) => {
        auth.login(data.redeemLogin.accessToken);
      })
      .catch((err) => setError(err.message ?? err.toString()));
  }, [params, loginMutation, auth]);

  if (error) {
    return <Center>{error}</Center>;
  }

  return null;
};

const LOGIN_MUTATION = gql`
  mutation LoginMutation($sessionId: ID!, $otpToken: String!) {
    redeemLogin(sessionId: $sessionId, otpToken: $otpToken) {
      accessToken
    }
  }
`;
