import { gql, useMutation, useQuery } from "@apollo/client";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { countBy, groupBy, upperFirst } from "lodash";
import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";
import { FloatingButton } from "../../components/FloatingButton";
import { OutfitListItemFragment } from "../../components/OutfitList";
import { CATEGORIES } from "../clothing/ClothingForm";

export const OutfitCreatePage: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [selectedCategory, setCategory] = useState<string>(CATEGORIES[0]);
  const [selectedItems, setSelectedItems] = useState<ClothingListItem[]>([]);

  const [createMutation] = useMutation(CREATE_OUTFIT_MUTATION, {
    update: (cache, { data: { createOutfit } }) => {
      cache.modify({
        fields: {
          outfits(existingOutfits = []) {
            const newOutfitRef = cache.writeFragment({
              data: createOutfit,
              fragment: OutfitListItemFragment,
              fragmentName: "OutfitListItem",
            });
            return [...existingOutfits, newOutfitRef];
          },
        },
      });
    },
  });

  const query = useQuery<OutfitCreatePageQueryResult>(OUTFIT_CREATE_PAGE_QUERY);
  const items = query.data?.clothingItems ?? [];
  const groupedItems = groupBy(items, "category");
  const groupedSelectionCounts = countBy(selectedItems, "category");
  const hasEnoughSelected = Object.keys(groupedSelectionCounts).length >= 2;

  const toggleItemSelection = (item: ClothingListItem) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((x) => x !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSubmit = async () => {
    if (processing) return;
    setProcessing(true);

    // generate combinations
    function cartesian(...a: Array<any>) {
      return a.reduce((a: any, b: Array<any>) =>
        a.flatMap((d: any) => b.map((e) => [d, e].flat()))
      );
    }
    const combinations: Array<ClothingListItem[]> = cartesian(
      ...Object.values(groupBy(selectedItems, "category"))
    );

    // create outfits
    for (const items of combinations) {
      await createMutation({ variables: { itemIds: items.map((x) => x.id) } });
    }

    // reset state
    setProcessing(false);
    setCategory(CATEGORIES[0]);
    setSelectedItems([]);

    // navigate to outfits list
    history.push("/main/wardrobe.outfits");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/main/wardrobe.outfits" />
          </IonButtons>
          <IonTitle>Create outfit</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonSegment
          value={selectedCategory}
          onIonChange={(e) => setCategory(e.detail.value!)}
        >
          {CATEGORIES.map((category) => (
            <IonSegmentButton key={category} value={category}>
              {upperFirst(category)}{" "}
              {groupedSelectionCounts[category] && (
                <>({groupedSelectionCounts[category]})</>
              )}
            </IonSegmentButton>
          ))}
        </IonSegment>
        <ClothingList
          items={groupedItems[selectedCategory] ?? []}
          selectedItems={selectedItems}
          onItemClick={toggleItemSelection}
        />
        <FloatingButton
          onClick={handleSubmit}
          disabled={!hasEnoughSelected || processing}
        >
          Generate outfits
        </FloatingButton>
      </IonContent>
    </IonPage>
  );
};

const OUTFIT_CREATE_PAGE_QUERY = gql`
  query OutfitCreatePageQuery {
    clothingItems {
      id
      category
      ...ClothingListItem
    }
  }
  ${ClothingListItemFragment}
`;

interface OutfitCreatePageQueryResult {
  clothingItems: ClothingListItem[];
}

const CREATE_OUTFIT_MUTATION = gql`
  mutation CreateOutfitMutation($itemIds: [ID!]!) {
    createOutfit(itemIds: $itemIds) {
      ...OutfitListItem
    }
  }
  ${OutfitListItemFragment}
`;
