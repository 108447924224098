import { gql, useQuery } from "@apollo/client";
import {
  IonButton,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
} from "@ionic/react";
import {
  camera,
  closeCircleOutline,
  filterCircleOutline,
} from "ionicons/icons";
import { upperFirst } from "lodash";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";
import { IntroArrow, IntroContent, IntroHeader } from "../../components/Intro";
import { WardrobeNavigation } from "../../components/WardrobeNavigation";
import { HapticsImpactStyle, useHaptics } from "../../hooks/useHaptics";
import { CATEGORIES } from "./ClothingForm";

export const ClothingListPage = () => {
  const history = useHistory();
  const haptics = useHaptics();

  const query = useQuery<ClothingListPageQueryResult>(CLOTHING_LIST_PAGE_QUERY);
  const items = query.data?.clothingItems ?? [];

  const [filtersVisible, showFilters] = useState(false);
  const [selectedCategory, setCategory] = useState<string | null>(null);
  const filteredItems =
    filtersVisible && selectedCategory
      ? items.filter((x) => x.category === selectedCategory)
      : items;

  return (
    <IonPage>
      <IonContent className="safe-area">
        <StyledFiltersWrapper>
          {filtersVisible && (
            <StyledFiltersContent>
              {CATEGORIES.map((category) => (
                <IonChip
                  onClick={() => setCategory(category)}
                  color={selectedCategory === category ? "dark" : "primary"}
                >
                  {upperFirst(category)}
                </IonChip>
              ))}
            </StyledFiltersContent>
          )}

          <IonButton onClick={() => showFilters((x) => !x)} fill="clear">
            <IonIcon
              icon={filtersVisible ? closeCircleOutline : filterCircleOutline}
            />
          </IonButton>
        </StyledFiltersWrapper>

        <ClothingList
          items={filteredItems}
          onItemClick={(item) =>
            history.push(`/main/wardrobe/clothing/${item.id}/edit`)
          }
        />

        {!query.loading && items.length === 0 && (
          <>
            <IntroHeader>Register item</IntroHeader>
            <IntroContent>
              <p>
                First things first, register an item by pressing the camera
                button below!
              </p>
              <p>
                Tip: Use a hanger to display your clothing item against a
                well-lit surface.
              </p>
            </IntroContent>
            <IntroArrow direction="bottom-right" />
          </>
        )}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={() => {
              haptics.impact({ style: HapticsImpactStyle.Light });
              history.push("/main/wardrobe/clothing/add");
            }}
          >
            <IonIcon icon={camera}></IonIcon>
          </IonFabButton>
        </IonFab>

        <WardrobeNavigation />
      </IonContent>
    </IonPage>
  );
};

const CLOTHING_LIST_PAGE_QUERY = gql`
  query ClothingListPageQuery {
    clothingItems {
      ...ClothingListItem
      category
    }
  }
  ${ClothingListItemFragment}
`;
type ClothingItem = ClothingListItem & {
  category: string;
};
interface ClothingListPageQueryResult {
  clothingItems: ClothingItem[];
}

const StyledFiltersWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const StyledFiltersContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-evenly;
`;
