import { gql, useQuery } from "@apollo/client";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";

import {
  ClothingList,
  ClothingListItem,
  ClothingListItemFragment,
} from "../../components/ClothingList";

export const AddItemModal: React.FunctionComponent<{
  showAddModal: boolean;
  setShowAddModal: (value: boolean) => void;
  excludeItemIds?: string[];
  onItemClick?: (item: ClothingListItem) => void;
}> = ({
  showAddModal,
  setShowAddModal,
  excludeItemIds = [],
  onItemClick = () => {},
}) => {
  const query = useQuery<AddItemModalQueryResult>(ADD_ITEM_MODAL_QUERY);
  const items = query.data?.clothingItems ?? [];

  const filteredItems = items.filter((x) => !excludeItemIds.includes(x.id));

  return (
    <IonModal
      isOpen={showAddModal}
      onDidDismiss={() => setShowAddModal(false)}
      swipeToClose
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>Select item to add</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowAddModal(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <ClothingList items={filteredItems} onItemClick={onItemClick} />
    </IonModal>
  );
};

const ADD_ITEM_MODAL_QUERY = gql`
  query AddItemModalQuery {
    clothingItems {
      ...ClothingListItem
    }
  }
  ${ClothingListItemFragment}
`;
interface AddItemModalQueryResult {
  clothingItems: ClothingListItem[];
}
